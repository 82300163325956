import { CLIENT_URL } from "./env"

export const PATH = {
  SERVICE: {
    HACKATHON: `${CLIENT_URL}/hackathon`,
    // レイドの詳細ページのパスを生成する関数
    getRaidDetail: (raidId: string) => `${CLIENT_URL}/hackathon/${raidId}`,
    // タブ付きのURLを生成する関数
    getRaidDetailWithTab: (raidId: string, tab: RaidTab) =>
      `${CLIENT_URL}/hackathon/${raidId}#${tab}`,
    INFO: `${CLIENT_URL}/settings/info`,
    NOTIFICATION: `${CLIENT_URL}/settings/notification`,
    ACCOUNT: `${CLIENT_URL}/settings/account`,
    REGISTER: "/auth/register",
    // <register>
    // ユーザーが初めてログインした後、追加情報を入力するページ
    // 既にユーザー登録が完了している場合は、メインページ（HACKATHON）にリダイレクト
    EMAIL_VERIFY: "/auth/email_verify",
    // <email_verify>
    // メール確認プロセスを管理するページ
    // メールが確認済みの場合、メインページ（HACKATHON）にリダイレクト
    OVERVIEW: "/overview",
    SPONSOR: "/sponsor",
    BLOG: "/blog",
    NEWS: "/news",
    MUSEUM: "/museum",
    CONTACT: "https://www.copalette.org/contact",
  },
  HELP: {
    INDEX: "/help",
    PRIVACY: "/help/privacy",
    TERMS: "/help/terms",
    PLAYER_POLICY: "/help/player-policy",
    PENALTY_RULES: "/help/penalty-rules",
    COMMON_RULES: "/help/common-rules",
    GLOSSARY: "/help/glossary",
  },
  ORGANIZE: {
    ROOT: "/organize/",
    WHAT_IS_HACKATHON: "/organize/about/what-is-hackathon",
    FLOW: "/organize/about/flow",
    CONTACT: "/organize/contact",
    MEDIA_KIT: "/organize/media_kit",
  },
  Auth: {
    LOGIN: "/api/auth/login",
    LOGOUT: "/api/auth/logout",
    SIGNUP: "/api/auth/signup",
    // <signup>
    // 新規ユーザー登録（サインアップ）処理を開始・Auth0の新規登録画面を開く
  },
  OTHER: {
    MEDIA_KIT:
      "https://drive.google.com/drive/folders/1Gzv9EP037sZaOTnuqsyEncxSw7RPvZKa?usp=sharing",
  },
} as const

// ハッカソン詳細ページのタブを定義
export const RAID_TABS = {
  OVERVIEW: "overview",
  SCHEDULE: "schedule",
  RULES: "rules",
  PLAYER_INFO: "player_info",
} as const

export type RaidTab = (typeof RAID_TABS)[keyof typeof RAID_TABS]
