import { PATH } from "@/constants/path"

export type SiteConfig = typeof siteConfig

export const siteConfig = {
  siteName: "CraftStadium",
  name: "CraftStadium | 熱狂的なものづくりのハッカソンスタジアム",
  description:
    "CraftStadium（クラスタ）は、熱狂と革新的な評価制度のハッカソンが開催されるスタジアム。実績が証明されるバッジや、ギルドでの切磋琢磨、新しい評価制度による真のプロダクトづくりを意識したエンジニアリングへ。エンジニアリングは大衆も熱狂するsportsだ。",
  absolutePath:
    "https://s3-copalette-dev.s3.ap-northeast-1.amazonaws.com/uGRsdUvteTH1L7b5wx3s5AzK9SaLQlwegYzQ4VJzTXt6hAztjEIHhoV44WZW48bN9LIqIHdlLPL73dfmfVrPPYA7FK4jsdPxGTJH",
  navItems: [
    {
      title: "ハッカソン",
      items: [
        {
          label: "ハッカソンを探す",
          href: PATH.SERVICE.HACKATHON,
        },
        {
          label: "サービス概要",
          href: "/overview",
        },
        {
          label: "スポンサーになる",
          href: PATH.SERVICE.SPONSOR,
        },
        {
          label: "ハッカソンを主催する",
          href: PATH.ORGANIZE.ROOT,
        },
      ],
    },
    {
      title: "ヘルプ",
      items: [
        {
          label: "ヘルプページ",
          href: PATH.HELP.INDEX,
        },
        {
          label: "用語集",
          href: PATH.HELP.GLOSSARY,
        },
        {
          label: "ハッカソン共通ルール",
          href: PATH.HELP.COMMON_RULES,
        },
        {
          label: "罰則点ルール",
          href: PATH.HELP.PENALTY_RULES,
        },
        {
          label: "プレイヤーポリシー",
          href: PATH.HELP.PLAYER_POLICY,
        },
        {
          label: "利用規約",
          href: PATH.HELP.TERMS,
        },
        {
          label: "プライバシーポリシー",
          href: PATH.HELP.PRIVACY,
        },
      ],
    },
    {
      title: "ブログ",
      items: [
        {
          label: "ブログ一覧",
          href: PATH.SERVICE.BLOG,
        },
        {
          label: "攻略法",
          href: "/blog/tag/complete-guide",
        },
        {
          label: "ハイライト",
          href: "/blog/tag/hilight",
        },
        {
          label: "サービス情報",
          href: "/blog/tag/stadium-info",
        },
        {
          label: "ハッカソン",
          href: "/blog/tag/hackathon",
        },
      ],
    },
  ],
  footerItems: [
    {
      title: "ハッカソン",
      items: [
        {
          label: "ハッカソンを探す",
          href: PATH.SERVICE.HACKATHON,
        },
        {
          label: "サービス概要",
          href: "/overview",
        },
        {
          label: "スポンサーになる",
          href: PATH.SERVICE.SPONSOR,
        },
        {
          label: "ヘルプ",
          href: PATH.HELP.INDEX,
        },
      ],
    },
    {
      title: "CraftStadium Organize",
      items: [
        {
          label: "ハッカソンを主催する",
          href: PATH.ORGANIZE.ROOT,
        },
        {
          label: "お問い合わせ",
          href: PATH.ORGANIZE.CONTACT,
        },
        {
          label: "導入までの流れ",
          href: PATH.ORGANIZE.FLOW,
        },
      ],
    },
    {
      title: "リソース",
      items: [
        {
          label: "ブログ",
          href: PATH.SERVICE.BLOG,
        },
        {
          label: "ニュース",
          href: PATH.SERVICE.NEWS,
        },
        {
          label: "美術館",
          href: PATH.SERVICE.MUSEUM,
        },
        {
          label: "メディアキット",
          href: PATH.ORGANIZE.MEDIA_KIT,
        },
        // FIX ME: ハッカソン結果ページ一覧ができるまでは意味をなさないのでコメントアウト
        // {
        //   label: "リザルト",
        //   href: "/hackathon/RR240629/result",
        // },
      ],
    },
    {
      title: "会社",
      items: [
        {
          label: "CoPalette Inc",
          href: "https://www.copalette.org/",
        },
        {
          label: "お問い合わせ",
          href: "https://www.copalette.org/contact",
        },
        {
          label: "Create with us - 採用",
          href: "https://copalette-app.notion.site/CoPalette-be90d79ce2214d5d8d1f4a9829826c3d?pvs=4",
        },
      ],
    },
  ],
  links: {
    x: "https://twitter.com/CraftStadium",
    discord: "https://discord.gg/kQvdQEa4uD",
    sponsor: "https://forms.gle/TTsfjLrxg658Q9u69",
  },
  errors: {
    notFound: {
      title: "404",
      message: "お探しのページ(URL)は見つかりませんでした",
      description:
        "URLに間違いがないかをご確認ください。ページの再読み込みをお試しください。",
    },
    forbidden: {
      title: "403",
      message: "アクセスが制限されています。",
      description: "ログインが必要な可能性があります。",
    },
    systemError: {
      title: "500",
      message: "システムエラーが発生しました。",
      description:
        "申し訳ございませんが、システムに問題が発生しました。時間をおいて再度お試しください。",
    },
    other: {
      title: "Error",
      message: "エラーが発生しました。",
      description:
        "予期せぬエラーが発生しました。時間をおいて再度お試しください。",
    },
  },
}
