// auth0
export const AUTH_DOMAIN = process.env["AUTH0_ISSUER_BASE_URL"]
export const AUTH_CLIENT_ID = process.env["AUTH0_CLIENT_ID"]
export const AUTH_CLIENT_SECRET = process.env["AUTH0_CLIENT_SECRET"]
export const AUTH_M2M_CLIENT_ID = process.env["AUTH0_M2M_CLIENT_ID"]
export const AUTH_M2M_CLIENT_SECRET = process.env["AUTH0_M2M_CLIENT_SECRET"]

export const SERVER_URL = process.env["NEXT_PUBLIC_SERVER_URL"]
export const CLIENT_URL = process.env["NEXT_PUBLIC_CLIENT_URL"]

// newt
export const NEWT_SPACE_ID = process.env["NEWT_SPACE_UID"]
export const NEWT_BLOG_API_TOKEN = process.env["NEWT_CDN_BLOG_API_TOKEN"]
export const NEWT_CDN_MUSEUM_TOKEN = process.env["NEWT_CDN_MUSEUM_API_TOKEN"]
export const NEWT_CDN_NEWSROOM_TOKEN =
  process.env["NEWT_CDN_NEWSROOM_API_TOKEN"]
